import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    text: {
      primary: '#313131',
      secondary: '#313131'
    }
  },
  typography: {
    body1: {
      fontFamily: ['Cabin', 'sans-serif'].join(','),
      fontSize: "18px"
    },
    body2: {
      fontFamily: ['Cabin', 'sans-serif'].join(',')
    },
    h1: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 'bold'
    },
    h2: {
      fontFamily: 'Roboto Condensed',
      fontWeight: 'bold'
    },
    h3: {
      fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
      fontWeight: 'bold'
    },
    h4: {
      fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
      fontWeight: 'bold'
    },
    h5: {
      fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
      fontWeight: 'bold'
    },
    h6: {
      fontFamily: 'Roboto Condensed',
      fontWeight: '700'
    },
    button: {
      fontFamily: 'Roboto Condensed',
      fontWeight: '700'
    }
  }
});

export default theme;
